import Registration from "./routes/private/registration";
import HeaderComponent from "./components/headerComponent";
import ResetPassword from "./routes/private/resetPassword";
import Booking from "./routes/public/booking";
import NotFound from "./routes/public/notFound";
import NotAuthorized from "./routes/public/notAuthorized";
import { Amplify } from "aws-amplify";
import awsConfig from "./config/AWSConfig";

import {
  HashRouter as Router,
  Switch,
  Route,
  withRouter,
  Redirect,
} from "react-router-dom";

import { Provider } from "react-redux";
import store from "./services/redux/store";
import {ConfigProvider} from 'antd'
import "./App.scss";
import { primaryColor, secondaryColor } from "./colors-config";
import {Helmet} from "react-helmet";
import DeleteUserAccountRequest from "./routes/private/deleteUserAccountRequest";
import SEO from "./components/shared/SEO/SEO";
import ResetPasswordRedirect from "./routes/private/resetPasswordRedirect";
Amplify.configure(awsConfig);

const Main = withRouter(({ location }) => {
  // logger.js
  // var DEBUG = false;
  // if (!DEBUG) {
  //   console.log = function () {};
  // }
  return (
    <Provider store={store}>
       <SEO/>
      <div className="App">
        {!location.pathname.includes("/registration") && (
          <HeaderComponent></HeaderComponent>
        )}
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/booking" />} />
          <Route exact path="/registration" component={NotAuthorized} />
          <Route exact path="/registration/:id" component={Registration} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/reset-password-redirect" component={ResetPasswordRedirect} />
          <Route exact path="/reset-password/:id" component={ResetPassword} />
          <Route exact path="/delete-user-data" component={DeleteUserAccountRequest} />
          

          <Route exact path="/booking/:clinicId" component={Booking} />


          <Route component={NotFound} />
        </Switch>
      </div>
    </Provider>
  );
});

function App() {
  return (
    <Router>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            // colorPrimary: "#00c6e9",
            colorPrimary: primaryColor,
            borderRadius: 4,
            // colorText:"#fff",
          },
          components: {
            Radio: {
              // buttonColor: "#fff",
              buttonSolidCheckedColor:secondaryColor,
              buttonCheckedBg:primaryColor,
              // colorText:"#fff",
            },
            Button:{
              colorTextLightSolid:secondaryColor,
            },
            Tag:{
              defaultColor:secondaryColor,
              defaultBg:primaryColor
            }
          },
        }}
      >
      <Main />
      </ConfigProvider>
    </Router>
  );
}

export default App;
