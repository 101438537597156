import React, { useState, useEffect } from "react";
import { Form, Input, Select, Button, Row, Col, Typography } from "antd";

import "../../assets/styles/components/passwordHandleComponent.scss";
import { confirmPasswordErrorMessage, passwordValidator } from "../../utils/patient";

const { Option } = Select;
const { Title } = Typography;

export default function ForgotPasswordHandleComponent({patientEmail, mode, forgotPassword }) {
  const [form] = Form.useForm();
  const [newPassword, setNewPassword] = useState();
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_+=.])[a-zA-Z\d!@#$%^&*()\-_+=.]{8,}$/;

useEffect(()=>{
  form.setFieldValue("email",patientEmail);
},[patientEmail])

  const submit = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        form.setFieldValue("email",patientEmail);
        forgotPassword( newPassword);
      })
      .catch((info) => {
        console.log("Hello");
      });
  };
  return (
    <div className="forgot-password-container ">
      <Row
        justify="start"
        style={{ backgroundColor: "white", borderRadius: "12px 12px 0 0" }}
      >
        <Col
          span={24}
          style={{ paddingLeft: "2rem", borderBottom: "1px solid lightgray" }}
        >
          <div className="forgot-password-title-container ">
            <Title
              level={2}
              style={{
                fontFamily: "'Poppins', sans-serif",
                marginBottom: "0.1rem",
              }}
            >
              {mode !== "change" ? "Forgot Password" : ""}
            </Title>

            <p
              style={{
                fontSize: "1rem",
                fontWeight: "300",
              }}
            >
              {mode !== "change"
                ? "Let’s create a new password for your account"
                : ""}
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        {" "}
        <Form
          style={{
            width: "100%",
            borderRadius: "6px",
          }}
          name="complex-form"
          layout={"vertical"}
          //   onFinish={onFinish}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 24 }}
          form={form}
        >
          <div className="forgot-password-form">
            <Form.Item
              label="Email"
              name="email"
              
              //   className="inline-form-left"
              style={{
                //width: "calc(95% - 1.5rem)",
                marginRight: "0.4rem",
                marginTop: "2rem",

                textAlign: "left",
              }}
            >
              <Input disabled={true} style= {{width: "calc(95% - 1.5rem)"}}/>
            </Form.Item>
            {/* <Form.Item
              label="Please enter the verification code sent to your email"
              name="code"
              //   className="inline-form-left"
              rules={[{ required: true, message: "OTP is required" }]}
              style={{
                //width: "calc(95% - 1.5rem)",
                marginRight: "0.4rem",
                marginTop: "2rem",

                textAlign: "left",
              }}
            >
              <Input style= {{width: "calc(95% - 1.5rem)"}}/>
            </Form.Item> */}

            <Form.Item
              label="New Password"
              name="newPassword"
              //   className="inline-form-left"
              rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                        {
                          pattern:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}()\-_+=!@#%^&*.,><’:;|~`])\S{8,99}$/,
                          message: "Invalid password",
                        },
                        {
                          validator: passwordValidator,
                        },
                      ]}
              style={{
                width: "calc(95% - 1.5rem)",
                marginRight: "0.4rem",
                marginTop: "2rem",

                textAlign: "left",
              }}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Confirm New Password"
              name="confirmPassword"
              //   className="inline-form-left"
              rules={[
                {
                  required: true,
                  message: "Password confirmation is required",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      setNewPassword(getFieldValue("newPassword"));

                      return Promise.resolve();
                    }

                    return Promise.reject(confirmPasswordErrorMessage);
                  },
                }),
              ]}
              style={{
                width: "calc(95% - 1.5rem)",
                marginRight: "0.4rem",
                marginTop: "2rem",

                textAlign: "left",
              }}
            >
              <Input.Password />
            </Form.Item>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <Button
              type="primary"
              onClick={submit}
              style={{
                width: "100%",
                borderRadius: "35px",
                height: "2.2rem",
                fontSize: "1rem",
                marginBottom: "2rem"
              }}
              //   onClick={() => onNext()}
              //   disabled={isMultiStepButtonDisabled}
            >
              Reset Password
            </Button>
          </div>
        </Form>
      </Row>
    </div>
  );
}
