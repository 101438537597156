import React from "react";
import { Link } from "react-router-dom";
import { Result, Button } from "antd";

const NotFound = () => (
  <div>
    {/* <h1>404 - Not Found!</h1>
    <Link to="/">Go Home</Link> */}
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      //   extra={
      //     <Link to="/">
      //       <Button type="primary">Back Home </Button>
      //     </Link>
      //   }
    />
    ,
  </div>
);

export default NotFound;
