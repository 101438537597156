import {
  CLINIC_LOADED,
  CLINIC_LOADING,
  CLINIC_LOADING_ERROR,
} from "../actions/types";

const initialState = {
  token: sessionStorage.getItem("token"),

  clinic: null,

  isLoading: true,
};

export default function patientReducer(state = initialState, action) {
  switch (action.type) {
    case CLINIC_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case CLINIC_LOADED:
      sessionStorage.setItem("token", action.payload.token);

      return {
        ...state,

        isLoading: false,
        clinic: action.payload,
        token: action.payload.token,
      };

    case CLINIC_LOADING_ERROR:
      return { ...state, isLoading: false, clinic: null };

    default:
      return state;
  }
}

// case AUTH_ERROR:

//     case REGISTER_FAIL:
//       return {
//         ...state,
//         user: null,
//         isAuthenticated: false,
//         isLoading: false,
//       };
