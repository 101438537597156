import { handleResponse, handleError } from "./response";

// Define your api url from any source.
// Pulling from your .env file when on the server or from localhost when locally
// const BASE_URL = "https://k83xp8c3n6.execute-api.eu-west-1.amazonaws.com/Stage/dev/api/v1/patient";
//

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getAll = (resource, config, axiosInstance) => {
  return axiosInstance
    .get(`${BASE_URL}/${resource}`, config)
    .then(handleResponse)
    .catch((err) => {
      throw handleError(err);
    });
};

const getSingle = (resource, config, axiosInstance) => {
  return axiosInstance
    .get(`${BASE_URL}/${resource}`, config)
    .then(handleResponse)
    .catch((err) => {
      throw handleError(err);
    });
};

const post = (resource, model, axiosInstance) => {
  return axiosInstance
    .post(`${BASE_URL}/${resource}`, model, {
      headers: { "Content-Type": "text/plain" },
    })
    .then(handleResponse)
    .catch((err) => {
      throw handleError(err);
    });
};

const put = (resource, model, axiosInstance) => {
  return axiosInstance
    .put(`${BASE_URL}/${resource}`, model)
    .then(handleResponse)
    .catch((err) => {
      throw handleError(err);
    });
};

const patch = (resource, model, axiosInstance) => {
  return axiosInstance
    .patch(`${BASE_URL}/${resource}`, model)
    .then(handleResponse)
    .catch((err) => {
      throw handleError(err);
    });
};

const remove = (resource, id, axiosInstance) => {
  return axiosInstance
    .delete(`${BASE_URL}/${resource}`, id)
    .then(handleResponse)
    .catch((err) => {
      throw handleError(err);
    });
};

export const apiProvider = {
  getAll,
  getSingle,
  post,
  put,
  patch,
  remove,
};
