import { ReactComponent as CalendarOff } from "../../../assets/images/CalendarOff.svg";
import { ReactComponent as Calendar } from "../../../assets/images/Calendar.svg";
import { ReactComponent as Email } from "../../../assets/images/Email.svg";
import { ReactComponent as Phone } from "../../../assets/images/Phone.svg";
import { ReactComponent as Address } from "../../../assets/images/Address.svg";

import styles from "./off.module.scss";

const OffAvailability = ({ clinicDetails }) => {
  return (
    <div className={styles["empty-container"]}>
      <div className={styles["empty-content"]}>
        <h2>
          <Calendar /> The appointment system has been disabled by your clinic.
          Please use the below information to contact the clinic directly.
        </h2>
        <div className={styles["clinic-details"]}>
          <p>Smile Genius Dental</p>
          <ul>
            <li>
              <Address />{" "}
              <span>
                {clinicDetails?.clinic_address
                  ? `${clinicDetails?.clinic_address},`
                  : ""}

                {clinicDetails?.clinic_city
                  ? `${clinicDetails?.clinic_city},`
                  : ""}
                {clinicDetails?.clinic_county
                  ? `${clinicDetails?.clinic_county},`
                  : ""}

                {clinicDetails?.clinic_country
                  ? `${clinicDetails?.clinic_country},`
                  : ""}
                {clinicDetails?.clinic_zipcode
                  ? `${clinicDetails?.clinic_zipcode}`
                  : ""}
              </span>{" "}
            </li>
            <li>
              <Phone />
              {clinicDetails && clinicDetails?.country_code_name
                ? clinicDetails.country_code_name +
                 "-" + clinicDetails?.clinic_contact
                : " "}{" "}
            </li>
            <li>
              <Email />{" "}
              {clinicDetails && clinicDetails?.clinic_email
                ? clinicDetails.clinic_email
                : " "}{" "}
            </li>
          </ul>
        </div>
        <CalendarOff />
      </div>
    </div>
  );
};

export default OffAvailability;
