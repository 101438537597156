import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Typography,
  Radio,
  Spin,
} from "antd";
import "../assets/styles/components/bookingFormComponent.scss";
import axios from "../api/api";
import { prefix } from "../colors-config";

const { Option } = Select;
const { Title } = Typography;

// const appointmentTypes = ["Suitability", "Retainer"];



export default function BookingFormComponent({
  onSubmit,
  newAppoinmentNames,
  appointmentTypes,
  setSelectedAppointmentName,
  setSelectedAppointmentType,
  shouldSubmit,
  setShouldSubmit,
}) {
  const [form] = Form.useForm();
  const [countryCodes, setCountryCodes] = useState([]);
  const [isSpinning, setIsSpinning] = useState(false);
  const [type, setType] = useState(1);

  useEffect(() => {
    form.resetFields();
    // console.log(form.getFieldsValue(true));
    const formValues = form.getFieldsValue(true);
    setSelectedAppointmentName(formValues.appointmentName);
    setSelectedAppointmentType(formValues.appointmentType);

    getCountryCodes();
  }, []);


  useEffect(() => {
    form.resetFields();
  }, [countryCodes]);

  const getCountryCodes = async () => {
    setIsSpinning(true);
    try {
      const res = await axios.get(
        "/patient/getAllCountries"
      );
      
      if(res?.data?.data?.data){
        const countriesList = res?.data?.data?.data;
        setCountryCodes(countriesList);
      }
     
      setIsSpinning(false);
    } catch (error) {
      setIsSpinning(false);

      console.log(error);
    }
  };

  useEffect(() => {
    if (shouldSubmit) {
      form.submit();
      setShouldSubmit(false);
    }
  }, [shouldSubmit]);

  const onFinish = (userDetails) => {
    // console.log(userDetails)
    onSubmit(userDetails);
  };
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        dropdownClassName="country-dropdown"
        className="country-select"
        size="large"
        dropdownStyle={{ width: "300px" }}
        style={{ width: 110 }}
        defaultValue={prefix}
        labelRender={(props) => {
          return props.label;
        }}
        options={countryCodes?.map((code, index) => {
          return {
            ...code,
            key: index,
            value: code.countryCallingCodes,
            title: `${code.emoji} ${code.countryCallingCodes}`,
            label: code.countryCallingCodes,
          };
        })}
        optionRender={(option) => {
          const { data } = option;
          return (
            <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr 10fr" }}>
              <span className="emoji-font">{data.emoji}</span>
              <span>{data.countryCallingCodes}</span>
              <span>| {data.name}</span>
            </div>
          );
        }}
        showSearch
        filterOption={(input, option) => {
          const lowerCasedInput = input.toLowerCase();
          return (
            option.value.toLowerCase().includes(lowerCasedInput) ||
            option.name.toLowerCase().includes(lowerCasedInput)
          );
        }}
      />
    </Form.Item>
  );

  const onValueChange = (val) => {
    if (val.appointmentName) {
      setSelectedAppointmentName(val.appointmentName);
    }
    if (val.appointmentType) {
      if (JSON.parse(val.appointmentType).clinic_appointment_type_id === 1) {
        setType(1);
        // const valclinic = JSON.stringify(setInital(newAppoinmentNames.clinic))
        // console.log(valclinic)
        // setSelectedAppointmentName(valclinic === undefined ? 'No Apooinments':valclinic)
      } else {
        setType(2);
        // const valVideo = JSON.stringify(setInital(newAppoinmentNames.video))
        // setSelectedAppointmentName(valVideo === undefined ? 'No Apooinments':valVideo)
        // setSelectedAppointmentName(setInital(newAppoinmentNames.video).appointmentName)
      }
      setSelectedAppointmentType(val.appointmentType);
    }
  };
  function setInital(val) {
    const setings = val.filter((i) => i?.clinic_appointment_type_name_id === 1);
    return setings.length !== 0 ? setings[0] : val[0];
  }
  return (
    <div className="booking-form-container">
      <Row justify="start">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            width: "100%",
          }}
        >
          <Title className="booking-header" level={4}>
            Add your details
          </Title>
          {/* <Button shape="round">
            <a
              href={
                process.env.REACT_APP_BASE_CLINIC +
                "/#/clinic/scheduling/pending?appointment=open"
              }
              target="_blank"
            >
              Back
            </a>
          </Button> */}
        </div>
      </Row>
      {/* <div className="booking-form-title-container ">
        <Row>
          <Title className="booking-form-title" level={5}>
            Add your details
          </Title>
        </Row>
      </div> */}
      <Spin spinning={isSpinning}>
        <Row>
          {" "}
          <Form
            style={{
              width: "100%",
              borderRadius: "6px",
            }}
            name="complex-form"
            layout={"vertical"}
            form={form}
            onValuesChange={onValueChange}
            initialValues={{
              appointmentName: JSON.stringify(
                type === 1
                  ? setInital(newAppoinmentNames.clinic)
                  : setInital(newAppoinmentNames.video)
              ),

              appointmentType: JSON.stringify(appointmentTypes[0]),
              prefix: prefix,
            }}
            onFinish={onFinish}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
          >
            <div className="booking-form">
              <Form.Item
                style={{
                  marginBottom: "-30px",
                  width: "100%",
                  marginTop: "0.5rem",
                }}
              >
                <Form.Item
                  label="First name"
                  name="firstName"
                  className="inline-form-left"
                  rules={[
                    { required: true, message: "First name is required" },
                    {
                      max: 50,
                      message: "First name cannot be more than 50 characters",
                    },
                  ]}
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 1.5rem)",
                    marginRight: "0.4rem",

                    textAlign: "left",
                  }}
                >
                  <Input size="large" placeholder="First Name" />
                </Form.Item>

                <Form.Item
                  label="Last name"
                  name="lastName"
                  rules={[
                    { required: true, message: "Last name is required" },
                    {
                      max: 50,
                      message: "Last name cannot be more than 50 characters",
                    },
                  ]}
                  className="inline-form-right"
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 1.5rem)",
                    margin: "0 0.5rem 0  2rem ",

                    textAlign: "left",
                  }}
                >
                  <Input size="large"  placeholder="Last Name" />
                </Form.Item>
              </Form.Item>

              <Form.Item
                style={{
                  marginBottom: "-30px",
                  width: "100%",
                  marginTop: "2.5rem",
                }}
              >
                <Form.Item
                  name="email"
                  label="Email address"
                  // style={{
                  //   marginRight: "0.5rem",
                  //   marginTop: "2.3rem",
                  // }}
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 1.5rem)",
                    marginRight: "0.4rem",

                    textAlign: "left",
                  }}
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Email" />
                </Form.Item>

                <Form.Item
                  name="phone"
                  label="Phone Number"
                  // style={{
                  //   marginRight: "0.5rem",
                  //   marginTop: "2rem",
                  // }}
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 1.5rem)",
                    margin: "0 0.5rem 0  2rem ",

                    textAlign: "left",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                    () => ({
                      validator(_, value) {
                        console.log(value, "value");
                        const convertedValue = Number(value);
                        if (!value) {
                          return Promise.resolve();
                        } else if (
                          !isNaN(convertedValue) &&
                          (value.length === 10 || value.length === 9)
                        ) {
                          return Promise.resolve();
                        }

                        return Promise.reject("Input must be a valid number");
                      },
                    }),
                  ]}
                >
                  <Input
                  size="large"
                    addonBefore={prefixSelector}
                    placeholder="Phone Number"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Form.Item>
              <Form.Item
                name="appointmentType"
                style={{ textAlign: "left", marginTop: "2rem" }}
                rules={[{ required: true, message: "Please pick a Type!" }]}
                label="Appointment Type"
              >
                <Radio.Group>
                  {appointmentTypes.map((appointmentType) => {
                    return (
                      <Radio
                        value={JSON.stringify(appointmentType)}
                        key={JSON.stringify(appointmentType)}
                        style={{ marginRight: "2.5rem" }}
                      >
                        {appointmentType.clinic_appointment_type_name}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </Form.Item>

              {/* <Form.Item className="parent-form-item"> */}
              <Form.Item
                label="Appointment Name"
                name="appointmentName"
                className="inline-form-left"
                rules={[
                  { required: true, message: "Appointment name is required" },
                ]}
                style={{
                  // display: "inline-block",
                  width: "calc(50% - 1.5rem)",
                  // marginRight: "0.4rem",

                  // textAlign: "left",
                }}
              >
                <Select
                size="large"
                  // defaultValue={productOrServiceTypeOptions[0].product_type}
                  style={{ width: "100%" }}
                  placeholder="Appointment Name"
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      {/* <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8,
                        }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          value={newProductOrServiceTypeOption}
                          onChange={onProductOrServiceTypeOptionChange}
                        />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer",
                          }}
                          onClick={addProductOrServiceTypeOption}
                        >
                          <PlusOutlined /> Add Type
                        </a>
                      </div> */}
                    </div>
                  )}
                >
                  {type === 1
                    ? newAppoinmentNames.clinic.map((item) => (
                        <Option key={JSON.stringify(item)}>
                          {item?.clinic_appointment_name}
                        </Option>
                      ))
                    : newAppoinmentNames?.video.map((item) => (
                        <Option key={JSON.stringify(item)}>
                          {item?.clinic_appointment_name}
                        </Option>
                      ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="isUnderAge"
                style={{ textAlign: "left", marginTop: "2rem" }}
                rules={[{ required: true, message: "Please pick an item!" }]}
                label="Are you over 17 years of age?"
              >
                <Radio.Group>
                  <Radio value={false}>Yes</Radio>
                  <Radio value={true}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            {/* <div style={{ marginTop: "1rem" }}>
            <Form.Item colon={false}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  float: "right",
                  borderRadius: "6px",
                  height: "2.5rem",
                }}
              >
                <Spin spinning={isSpinning}>
                  Make Appointment <InfoCircleOutlined />
                </Spin>
              </Button>
            </Form.Item>
          </div> */}
          </Form>
        </Row>
      </Spin>
    </div>
  );
}
