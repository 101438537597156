import React, { useState, useEffect } from "react";

import { Col, Row, Typography, Spin, Space, Button, message, Modal, notification } from "antd";

import SlotsComponent from "../../components/slotsComponent";
import BookingFormComponent from "../../components/bookingFormComponent";
import CalendarComponent from "../../components/calendarComponent";
import OffAvailability from "../../components/shared/OffAvailability/OffAvailability";
import errorModal from "../../components/shared/modals/errorModal";

import AppointmentResultComponent from "../../components/public/appointmentResultComponent";
import { loadClinic } from "../../services/redux/actions/patientActions";

import { patientApiTasks } from "../../models/patient";

import { connect } from "react-redux";
import { CLINIC_LOADING_ERROR } from "../../services/redux/actions/types";

import "../../assets/styles/routes/booking.scss";
import axios from "../../api/api";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import { getClinicNewWorkingDays, getHolidays } from "../../utils/patient";
import { getProfileAndAvailability } from "../../api/patient";

dayjs.extend(utc)
dayjs.extend(timezone)

const { Title } = Typography;

const Booking = ({ loadClinic, clinic, isLoading, error, match }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  // const [userDetails, setUserDetails] = useState({});
  const [clinicId] = useState(match.params.clinicId);
  const [selectedDateObject, setSelectedDateObject] = useState(null);
  const [slots, setSlots] = useState([]);
  const [bookingMade, setBookingMade] = useState(false);
  const [offDays, setOffDays] = useState(null);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  // const [appointmentNames, setAppointmentNames] = useState([]);
  const [selectedAppointmentName, setSelectedAppointmentName] = useState("");
  const [selectedAppointmentType, setSelectedAppointmentType] = useState("");
  const [newAppoinmentNames, setNewAppoinmentNames] = useState({ clinic: [], video: [] })

  const [isComponentSpinning, setIsComponentSpinning] = useState(true);
  const [isFormSpinning, setIsFormSpinning] = useState(false);
  const [isSubmitButtonSpinning, SetIsSubmitButtonSpinning] = useState(false);
  const [shouldFormSubmit, setShouldFormSubmit] = useState(false);
  const [clinicianNotAvailable,setClinicianNotAvailable] = useState(false)
  const [clinicAvailability, setClinicAvailability] = useState(true)
  const [clinicDetails, setClinicDetails] = useState()
  // const parseAppointmentNames = (appointmentNameObject) => {
  //   const appointmentObject = {};
  //   appointmentNameObject?.map((appointment) => {
  //     appointmentObject[appointment[0].appointmentType] =
  //       appointment[1].appointmentNames.map((appointmentName) => {
  //         return appointmentName;
  //       });
  //   });
  //   return Object.values(appointmentObject).flat(2);
  // };

  const parseAppointmentTypes = (appointmentTypeObject) => {
    const appointmentTypes = appointmentTypeObject?.map((appointmentType) => {
      return appointmentType;
    });
    return appointmentTypes;
  };
  function parseTwoValue() {
    let inclinic = []
    let video = []
    clinic.clinicAppointmentNames.forEach(i => ((
      i[0].appointmentType === 1 ? inclinic.push(i[1].appointmentNames[0]) : video.push(i[1].appointmentNames[0])
    )))
    setNewAppoinmentNames({ clinic: inclinic, video })
  }
  const parseWorkDays = (workDays) => {
    return workDays.map((workday) => {
      return workday.workday_number;
    });
  };


  useEffect(() => {
    loadClinic(clinicId, _getOffDays);
  }, []);

  const loadClinicAvailability = async () =>{
    try {
        const  response  = await getProfileAndAvailability({ clinicId: clinicId });
        setClinicDetails(response?.body.profile)
         setClinicAvailability(response?.body.profile.clinic_availability)
      
    } catch (error) {
      console.log(error);
  
    }
    finally{

    }
  }

useEffect(() =>{
  if (clinicId) {
    loadClinicAvailability()
  }

}, [clinicId])

  useEffect(() => {
    if (selectedDateObject) {
      _getSlots(selectedDateObject);
    }
  }, [selectedAppointmentType, selectedAppointmentName]);

  useEffect(() => {
    if (error.id && error.id === CLINIC_LOADING_ERROR) {
      if (error.status == 404) {
        errorModal("Clinic does not exist");
      } else {
        errorModal(error.msg);
      }
    }
  }, [error]);

  useEffect(() => {
    setSelectedSlot(null);
  }, [selectedDate]);

  useEffect(() => {
    if (clinic && clinicAvailability) {
      if (clinic?.clinicAppointmentType?.length === 0) {
        notification.error({
          message: 'Error',
          description: 'The clinicAppointmentType is empty.',
        })
      } else if (clinic?.clinicAppointmentNames?.length === 0) {
        notification.error({
          message: 'Error',
          description: 'The clinicAppointmentNames is empty.',
        })
      } else {
        parseTwoValue(clinic.clinicAppointmentNames)
        setAppointmentTypes(parseAppointmentTypes(clinic.clinicAppointmentType));
        // setAppointmentNames(parsingAppoinmentName(clinic.clinicAppointmentNames?.flat(2)));
      }

    }
  }, [clinic, clinicAvailability]);

  const promptUserToRefreshModal = (error) => {
    if (error.status === 401 && error.data.includes("Token expired")) {
      errorModal(
        "Token has expired. Please click on Ok to refresh the page and get new token.",
        () => {
          window.location.reload();
        }
      );
    } else {
      if (error && error.data && error.data.err) {
        errorModal(error?.data?.err?.msg);
      }
    }
  };

  const _getOffDays = async () => {
    try {
      const res = await patientApiTasks.getAll("clinic/getcalendar", {
        clinicId,
      });

      
      let newWorkingDays = {}
      if (res?.body?.workingDays?.workingHours) {
        newWorkingDays = getClinicNewWorkingDays(res?.body?.workingDays?.workingHours)
      }
      const holidays = getHolidays(res?.body?.holidays);
      const holidayDates = res?.body?.holidays.map(date => dayjs(date).format('YYYY-MM-DD'));
      setClinicianNotAvailable(holidayDates.includes(selectedDate))
      // const parsedWorkDays = parseWorkDays(workdays);

      const offDays = {
        workingDays:newWorkingDays,
        newWorkingDays,
        holidays: holidays || {},
        wokringPeriod: res?.body?.working_period || [],
      } || null;
      console.log(offDays, "==Off days");

      setOffDays(offDays);
    } catch (err) {
      console.log(err);
    }
  };

  const _getSlots = async (date) => {
    const dateStr = date.format("YYYY-MM-DD");
    setIsComponentSpinning(true);

    try {
      const timezone = dayjs.tz.guess();
      const res = await patientApiTasks.getAll("clinic/getTimeSlot", {
        clinicId,
        time: date.format("HH:mm:ss"),
        // dateString: dateStr,
        appointmentDate: dateStr,
        appointmentNameId: JSON.parse(selectedAppointmentName)
          .clinic_appointment_type_name_id,
        appointmentTypeId: JSON.parse(selectedAppointmentType)
          .clinic_appointment_type_id,
        timezone: timezone
      });
      console.log("date");
      setSlots(res.body.timeSlots);
      setIsComponentSpinning(false);
    } catch (error) {
      console.log(error);
      setIsComponentSpinning(false);
      promptUserToRefreshModal(error);
    }
  };

  const _makeAppointment = async (model) => {
    setIsFormSpinning(true);
    SetIsSubmitButtonSpinning(true);
    const response = await axios.post(`/patient/makeAppointment`, model)
    if (response?.data?.err?.msg === 'The user email already exists.') {
      notification.error({
        message: 'Error',
        description: 'The user email already exists.',
      })
      setIsFormSpinning(false);
      SetIsSubmitButtonSpinning(false);
      return false
    }
    setBookingMade(true);


    // try {
    //   // console.log(model)
    // await axios.post(`${process.env.REACT_APP_API_BASE_URL}/patient/makeAppointment`,model).then(res => Modal.error({ title: "User Exist"}))
    // await patientApiTasks.post("patient/makeAppointment", model);
    // setIsFormSpinning(false);
    // SetIsSubmitButtonSpinning(false);

    // setBookingMade(true);
    // } catch (error) {
    //   setIsFormSpinning(false);
    //   SetIsSubmitButtonSpinning(false);

    //   promptUserToRefreshModal(error);
    //   //Modal.info({title:"error dummy",content:"error content"})
    // }
  };

  const onDateChange = (dateStr, dateObject) => {
    setSelectedDate(dateStr);
    setIsComponentSpinning(true);

    setSelectedDateObject(dateObject);

    _getSlots(dateObject);
  };

  const onSlotSelectChange = (slot) => {
    console.log(slot);
    setSelectedSlot(slot);
  };

  const shouldCalendarRender = () => {
    return selectedAppointmentName && selectedAppointmentType;
  };

  const onSubmit = (userDetails) => {
    let {
      firstName,
      lastName,
      email,
      isUnderAge,
      phone: phoneNumber,
      prefix,
    } = userDetails;
    phoneNumber = phoneNumber;
    
    if(clinicianNotAvailable && !selectedSlot){
      message.error("Clinician not available. Please select next available slot")
      return;
    }

    if (!selectedSlot) {
      message.error("Please select a slot");
      return;
    } else {
      const timezone = dayjs.tz.guess();
      const requestObject = {
        firstName,
        lastName,
        email,
        isUnderAge,
        phoneNumber,
        clinicId,
        countryCode: prefix,
        appointmentDate: selectedDateObject.format("YYYY-MM-DD"),
        appointmentNameId: JSON.parse(selectedAppointmentName)
          .clinic_appointment_type_name_id,
        appointmentTypeId: JSON.parse(selectedAppointmentType)
          .clinic_appointment_type_id,
        appointmentName: JSON.parse(selectedAppointmentName)
          .clinic_appointment_name,
        appointmentType: JSON.parse(selectedAppointmentType)
          .clinic_appointment_type_name,
        appointmentStartTime: selectedSlot.appointmentStartTime,
        appointmentEndTime: selectedSlot.appointmentEndTime,
        appointmentSlotId: selectedSlot.appointmentSlotId,
        timezone: timezone
      };
      _makeAppointment(requestObject);
    }

    console.log(userDetails);
    console.log(selectedSlot);
  };

  const shouldBookingRender = () => {
    // return !bookingMade && !isLoading && !error.id;
    // return true;
    return (
      !bookingMade && !isLoading && !error.id && appointmentTypes.length > 0
    );
  };

  return (
    <div className="booking-container">
    {
      clinicAvailability ? <div>{isLoading && (
        <Space style={{ marginTop: "2rem" }} size="middle">
          <Spin size="large" />
        </Space>
      )}
      {bookingMade && <AppointmentResultComponent ></AppointmentResultComponent>}

      {shouldBookingRender() && (
        <div>
          <Row>
            <Col
              xxl={{ span: 22, offset: 2 }}
              xl={{ span: 22, offset: 2 }}
              lg={{ span: 22, offset: 2 }}
              md={{ span: 24, offset: 0 }}
            >
              <div className="booking-title">
                <Row>
                  <Title
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                    }}
                    level={2}
                  >
                    Book appointment with {clinic.clinicName}
                  </Title>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col
              xxl={{ span: 20, offset: 2 }}
              xl={{ span: 20, offset: 2 }}
              lg={{ span: 20, offset: 2 }}
              md={{ span: 24, offset: 0 }}
            >
              <BookingFormComponent
                onSubmit={onSubmit}
                isSpinning={isFormSpinning}
                newAppoinmentNames={newAppoinmentNames}
                // appointmentNames={appointmentNames}
                appointmentTypes={appointmentTypes}
                setSelectedAppointmentName={setSelectedAppointmentName}
                setSelectedAppointmentType={setSelectedAppointmentType}
                setShouldSubmit={setShouldFormSubmit}
                shouldSubmit={shouldFormSubmit}
              ></BookingFormComponent>
            </Col>
          </Row>
          <Row>
            <Col
              xxl={{ span: 5, offset: 2 }}
              xl={{ span: 6, offset: 2 }}
              lg={{ span: 7, offset: 2 }}
              md={{ span: 24, offset: 0 }}
            >
              {shouldCalendarRender() && (
                <CalendarComponent
                  onDateChange={onDateChange}
                  isSpinning={isComponentSpinning}
                  clinicWorkingSchedule={offDays}
                ></CalendarComponent>
              )}
            </Col>
            <Col
              xxl={{ span: 15, offset: 0 }}
              xl={{ span: 14, offset: 0 }}
              lg={{ span: 5, offset: 0 }}
              md={{ span: 24, offset: 0 }}
            >
              <SlotsComponent
                slots={slots}
                date={selectedDate}
                onSlotSelectChange={onSlotSelectChange}
                isSpinning={isComponentSpinning}
                isSubmitButtonSpinning={isSubmitButtonSpinning}
                setShouldFormSubmit={setShouldFormSubmit}
              ></SlotsComponent>
            </Col>
            {/* </Row> */}
          </Row>
        </div>
      )}</div> : <OffAvailability clinicDetails={clinicDetails} info="Your clinic availability is Off. "/>
    }
      
    </div>
  );
};

const mapStateToProps = (state) => ({
  clinic: state.patient.clinic,
  error: state.error,
  isLoading: state.patient.isLoading,
});

export default connect(mapStateToProps, { loadClinic })(Booking);
