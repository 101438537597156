import axios from "axios";
const baseUrl = process.env.REACT_APP_API_BASE_URL;
const tenantId = process.env.REACT_APP_SMILE_TENENT_ID;

const instance = axios.create({
  baseURL: baseUrl,
  timeout: 100000,
//   headers: {'tenantId': tenantId}
});

instance.interceptors.request.use(
  (config) => {
    if (tenantId) {
      config.headers["tenantId"] = tenantId;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default instance;
