import React, { useState, useEffect } from "react";
import { message } from "antd";
import qs from "qs";
import { playStoreUrl,iosStoreUrl } from "../../colors-config";


export default function ResetPasswordRedirect({ location, match }) {
  const [patientEmail, setPatientEmail] = useState("");
  const [patientCode, setPatientCode] = useState("");
  const [url, setUrl] = useState("");



  useEffect(() => {
    var obj = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (obj && obj.email && obj.code) {
      setPatientEmail(obj.email);
      setPatientCode(obj.code);

    }
    else {
      message.error("Code is expired");
    }

  }, []);

  useEffect(() => {
    if (patientEmail && patientCode) {
      // const deepLinkUrl = `${process.env.REACT_APP_DEEPLINK_URL}://?email=${patientEmail}&code=${patientCode}`;
      const deepLinkUrl = `com.app.smilegeniusdental://?email=${patientEmail}&code=${patientCode}`;
      const fallbackUrl = `${process.env.REACT_APP_PATIENT_PORTAL}/#/reset-password?email=${patientEmail}&code=${patientCode}`;
      // Check if the user is on a mobile device
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      let url=""
      if (isMobile) {
        window.location = deepLinkUrl;
        // url= deepLinkUrl;
        // setUrl(url)
        setTimeout(() => {
          const isGoogle = /Android/i.test(navigator.userAgent);
          window.location = isGoogle ? playStoreUrl : iosStoreUrl;
          // url = isGoogle ? playStoreUrl : iosStoreUrl;
          // setUrl(url)
        }, 2000);
      } else {
        window.location = fallbackUrl;
        // url = fallbackUrl;
        // setUrl(url)
      }
    }
  }, [patientEmail, patientCode])

  return (
    <div>
      Redirecting.....
      {/* {url} */}
    </div>
  );
}
