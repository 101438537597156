import React from "react";

import { Radio, Row, Typography, Spin, Col, Button } from "antd";
import moment from "moment";
import "../assets/styles/components/slotsComponent.scss";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Title } = Typography;

export default function SlotsComponent({
  slots,
  date,
  onSlotSelectChange,
  setShouldFormSubmit,
  isSubmitButtonSpinning,
  isSpinning,
}) {

   const timeZoneConvert = (time) => {
    try {
      var date = new Date(`2012-11-29 ${time} UTC`.replace(/-/g, "/"));
      return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

    } catch (ex) {
        // If time format incorrect
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      if (time.length > 1) {
        time = time.slice(1); // Remove fulwl string match value
        time[3] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join('');
    }
    
  };
  const createSlotButtons = (slots) => {
    
    return slots.map((slot) => {
      // const time = timeZoneConvert(slot.appointmentStartTime);
      const time = moment(slot.appointmentStartTime,"HH:mm:ss").format("hh:mm A");
      return (
        <Radio className="slot-buttons" value={slot}>
          {time}
        </Radio>
        // <div></div>
      );
    });
  };

  const onSlotChanged = (slot) => {
    onSlotSelectChange(slot);
  };

  return (
    <div className="slot-outer-container">
      <div className="slots-container">
        <div className="slots-title-container">
          <Row justify="start" align="middle">
            <Title className="slots-title" level={5}>
              Available slots
            </Title>
            {date && (
              <p
                style={{
                  color: "#77849E",
                }}
              >
                {date}
              </p>
            )}
          </Row>
        </div>
        {!slots.length && isSpinning && (
          <Spin style={{ margin: "2rem" }}></Spin>
          // <p
          //   style={{ marginTop: "2rem", marginBottom: "2rem", fontSize: "16px" }}
          // >
          //   No slots available!
          // </p>
        )}
        {!slots.length && !isSpinning && (
          <p
            style={{
              marginTop: "7rem",
              paddingBottom: "3rem",
              fontSize: "16px",
              fontWeight: "550",
            }}
          >
            No slots available!
          </p>
        )}
        {slots.length > 0 && (
          <Spin spinning={isSpinning}>
            <Row>
              <Radio.Group
                size="medium"
                buttonStyle="solid"
                onChange={(e) => {
                  onSlotChanged(e.target.value);
                }}
              >
                <div className="radio-buttons-container">
                  {createSlotButtons(slots)}
                </div>
              </Radio.Group>
            </Row>
          </Spin>
        )}
      </div>
      <Row justify="end">
        <Col>
          <div style={{ marginTop: "1rem" }}>
            <Button
              type="primary"
              onClick={() => setShouldFormSubmit(true)}
              style={{
                float: "right",
                borderRadius: "20px",
                height: "2.5rem",
                width: "12rem",
              }}
            >
              <Spin spinning={isSubmitButtonSpinning}>
                Make Appointment <InfoCircleOutlined />
              </Spin>
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}
