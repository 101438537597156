import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, message, Typography } from "antd";
import axios from "../../api/api";


const { Title } = Typography;
const { TextArea } = Input;

export default function DeleteUserAccountRequest() {


  const [isSpinning, setIsSpinning] = useState(false);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      setIsSpinning(true)
       await axios.post(`/patient/auth/addPatientDeleteRequest`,values);
      message.success("Request successfully submitted!");
      form.resetFields();
    } catch (error) {
      
      message.error(error?.response?.data?.message ? error?.response?.data?.message : error.message)
     
    }
    finally{
      setIsSpinning(false);
    }
    console.log("values", values);
  }


  return (
    <div>

      <div
        style={{
          width: "45rem",
          margin: "4rem auto ",
        }}
      >
        <div className="forgot-password-container ">
          <Row
            justify="start"
            style={{ backgroundColor: "white", borderRadius: "12px 12px 0 0" }}
          >
            <Col
              span={24}
              style={{ paddingLeft: "2rem", borderBottom: "1px solid lightgray" }}
            >
              <div className="forgot-password-title-container ">
                <Title
                  level={2}
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    marginBottom: "0.1rem",
                  }}
                >
                  Delete User Data
                </Title>

                <p
                  style={{
                    fontSize: "1rem",
                    fontWeight: "300",
                  }}
                >
                  Let’s request to delete data
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            {" "}
            <Form
              style={{
                width: "100%",
                borderRadius: "6px",
              }}
              name="complex-form"
              layout={"vertical"}
              onFinish={onFinish}
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 24 }}
              form={form}
            >
              <div className="forgot-password-form">
                <Form.Item
                  label="Name"
                  name="name"
                  style={{
                    marginRight: "0.4rem",
                    marginTop: "2rem",

                    textAlign: "left",
                  }}
                  rules={[
                    { required: true, message: "Name is required" },
                  ]}
                >
                  <Input style={{ width: "calc(95% - 1.5rem)" }} />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  style={{
                    marginRight: "0.4rem",
                    marginTop: "2rem",

                    textAlign: "left",
                  }}
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    { required: true, message: "Email is required" },
                  ]}
                >
                  <Input style={{ width: "calc(95% - 1.5rem)" }} />
                </Form.Item>
                <Form.Item
                  label="Comment"
                  name="comment"
                  style={{
                    marginRight: "0.4rem",
                    marginTop: "2rem",

                    textAlign: "left",
                  }}
                >
                  <TextArea rows={4} style={{ width: "calc(95% - 1.5rem)" }} placeholder="Enter you comment" />
                </Form.Item>

              </div>
              <div style={{ marginTop: "1rem" }}>
                <Button
                htmlType="submit"
                  type="primary"
                  style={{
                    width: "100%",
                    borderRadius: "35px",
                    height: "2.2rem",
                    fontSize: "1rem",
                    marginBottom: "2rem"
                  }}
                  loading={isSpinning}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Row>
        </div>
      </div>
    </div>
  );
}
