import { returnErrors } from "./errorActions";
import {
  AUTH_ERROR,
  CLINIC_LOADING,
  CLINIC_LOADED,
  CLINIC_LOADING_ERROR,
} from "./types";

import { patientApiTasks } from "../../../models/patient";
import { patientAxiosInstance } from "../../../models/patient";

export const tokenExpired = () => async (dispatch) => {
  dispatch({
    type: AUTH_ERROR,
  });
};
// Check token & load user
export const loadClinic = (clinicId, getOffDays) => async (
  dispatch,
  getState
) => {
  // User loading
  dispatch({ type: CLINIC_LOADING });

  patientApiTasks
    .getClinic("patient/fetchIp", { clinicId: clinicId })
    .then(async (res) => {
      if (res && res.body) {
        patientAxiosInstance.defaults.headers[
          "Authorization"
        ] = `Bearer ${res.body.token}`;
        await dispatch({ type: CLINIC_LOADED, payload: res.body });
        getOffDays();
      }
    })
    .catch((err) => {
      console.log(err);

      if (err) {
        dispatch(returnErrors(err, err.status, CLINIC_LOADING_ERROR));
      }

      dispatch({
        type: CLINIC_LOADING_ERROR,
      });
    });
};
