import moment from "moment";
export const passwordValidator = (_, value) => {
    if (value.length < 8) {
      return Promise.reject("Password must be at least 8 characters long");
    }
    if (!/(?=.*[a-z])/.test(value)) {
      return Promise.reject("At least 1 lowercase alphabetical character is required");
    }
    if (!/(?=.*[A-Z])/.test(value)) {
      return Promise.reject("At least 1 uppercase alphabetical character is required");
    }
    if (!/(?=.*[0-9])/.test(value)) {
      return Promise.reject("At least 1 numeric character is required");
    }
    if (!/(?=.*[!@#$%^&*()\-_=+.])/.test(value)) {
      return Promise.reject("At least 1 special character is required (! @ # $ % ^ & * ( ) - _ = + .)");
    }
    if (/[^!@#$%^&*()\-_=+a-zA-Z0-9.]/.test(value)) {
      return Promise.reject(
        "A special character that you may have entered is unsupported, please use one or more from the following list ! @ # $ % ^ & * ( ) - _ = + ."
      );
    }
    return Promise.resolve();
  };

  export const confirmPasswordErrorMessage ="The passwords entered do not match! Please review and enter the same passwords in both fields."

  const getWeekNumber = (name) => {
    const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const getIndex=weekDays.findIndex(obj=>obj==name);
    return getIndex;
  }

  export const getClinicNewWorkingDays = (workindDaysArray) => {
    const workingDays = {};
    if (workindDaysArray && workindDaysArray.length) {
      workindDaysArray.forEach((data) => {
        if(data.workDay){
          const weekNumber = getWeekNumber(data.name);
          workingDays[weekNumber] = weekNumber;
        }
       
      });
    }
  
    return workingDays;
  };

  export const getHolidays = (holidays) => {
    const days = {};
  
    if (holidays && holidays.length) {
      holidays.forEach((el) => {
        const val = moment(el).startOf("day").valueOf();
        days[val] = val;
      });
    }
    return days;
  };