import clearX from "./assets/images/clearx-banner.jpg";
import logo from "./assets/images/clear-x-logo-black.png";
import sglogo from "./assets/images/Smile-Genius-Logo-copy-2.png";
import sgimage from "./assets/images/Registration-Webpage-2.png";
import smileAlignLoginPage from "./assets/images/smilealign-login-page.jpg";
import smileAlignLogo from "./assets/images/smile-align-logo.png";
import iasLoginPage from "./assets/images/ias-login-page.png";
import iasLogo from "./assets/images/ias-logo.png";
import aquaLogo from "./assets/images/aquaaligner_logo.jpg";
import aquaLoginPage from "./assets/images/aqua_signup.jpg";
// "#00b96b"
//"#1F3332"
//"#41ACA4"
let primaryColor = "";
let secondaryColor = "";
let logoColor = "";
let LogInPageLogo = "";
let headerColor = "";
let sidebarColor = "";
let headerLogo = "";
let companyName = "";
let prefix = '+353'

let playStoreUrl = "https://play.google.com/store/apps/details?id=com.smilegenius.patient";
let iosStoreUrl = "https://apps.apple.com/us/app/smile-genius/id1571960049";

const companyType = Number(process.env.REACT_APP_COMPANY_TYPE);
if (companyType == 4) {
    primaryColor = "#1F3332";
    secondaryColor = "#fff";
    logoColor = "#41ACA4"
    LogInPageLogo = clearX
    headerColor = '#0a1715'
    sidebarColor = '#0a1715';
    headerLogo = logo;
    companyName = "Clearx Classic";
    prefix = '+44'
}
else if (companyType == 5) {
    primaryColor = "#DE8754";
    secondaryColor = "#fff";
    logoColor = "#41ACA4";
    headerColor = "#ffffff";
    sidebarColor = "#DE8754";

    headerLogo = smileAlignLogo;
    LogInPageLogo = smileAlignLoginPage

    companyName = "Smilelign";

    playStoreUrl = "https://play.google.com/store/apps/details?id=com.app.smilegelign.patient";
    iosStoreUrl = "https://apps.apple.com/us/app/smilelign-app/id6472892532";
    prefix = '+44'
}
else if (companyType == 6) {
    primaryColor = "#539ad1";
    secondaryColor = "#fff";
    logoColor = "#41ACA4";
    headerColor = "#ffffff";
    sidebarColor = "#539ad1";

    headerLogo = iasLogo;
    LogInPageLogo = iasLoginPage

    companyName = "IAS Academy";

    playStoreUrl = "https://play.google.com/store/apps/details?id=com.app.ias.patient";
    iosStoreUrl = "https://apps.apple.com/us/app/iasortho/id6480270975";
    prefix = '+44'
}

else if (companyType == 7) {
    primaryColor = "#00bff2";
    secondaryColor = "#fff";
    logoColor = "#f205e2";
    LogInPageLogo = aquaLoginPage;
    headerColor = "#fff";
    sidebarColor = "#00c6e9";
    headerLogo = aquaLogo;
    companyName = "Aqua Aligner";

    playStoreUrl = "https://play.google.com/store/apps/details?id=com.app.aqua.aligners";
    iosStoreUrl = "https://apps.apple.com/us/app/aqua-aligner/id6502831029";
    prefix = '+971'
}
else {
    primaryColor = "#00c6e9";
    secondaryColor = "#fff";
    logoColor = "#f205e2";
    LogInPageLogo = sgimage;
    headerColor = "#fff";
    sidebarColor = "#00c6e9";
    headerLogo = sglogo;
    companyName = "Smile Genius";

    playStoreUrl = "https://play.google.com/store/apps/details?id=com.smilegenius.patient";
    iosStoreUrl = "https://apps.apple.com/us/app/smile-genius/id1571960049";
    prefix = '+353'
}



export {
    primaryColor,
    secondaryColor,
    logoColor,
    LogInPageLogo,
    headerColor,
    sidebarColor,
    headerLogo,
    playStoreUrl,
    iosStoreUrl,
    companyName,
    companyType,
    prefix
};
