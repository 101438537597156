import errorModal from "../../../components/shared/modals/errorModal";

export function handleResponse(response) {
  if (response.results) {
    return response.results;
  }

  if (response.data) {
    return response.data;
  }

  return response;
}

export function handleError(error) {
  console.dir(error, "error");
  // if (error.data) {

  //   return error.data;
  // }
  // if (
  //   error.response.status === 401 &&
  //   error.response.data === "Invalid token"
  // ) {
  //   errorModal(
  //     "Token has expired. Please click on Ok to refresh the page and get new token.",
  //     () => {
  //       window.location.reload();
  //     }
  //   );
  // }
  return error.response;
}
