import React from "react";

import { Card, Typography } from "antd";
import image from "../../assets/images/check-icon.png";
import apple from "../../assets/images/apple.svg";
import google from "../../assets/images/google.svg";
import { companyName, iosStoreUrl, playStoreUrl } from "../../colors-config";

const { Title } = Typography;


export default function RegistrationResultComponent() {

  return (
    <Card
      className="successPopup"
    >
      <Title
        level={2}
        style={{
          fontFamily: "'Poppins', sans-serif",
          width: "20rem",

          margin: "10%  auto 0 auto",
        }}
      >
        Thank you for Registering with us!
      </Title>

      <img
        style={{
          marginTop: "7%",
          //marginBottom: "10%",

          // backgroundColor: "red",
          //   marginRight: "2rem",
        }}
        src={image}
        alt="email logo"
      />
      <p style={{ fontSize: "1rem", color: "#89909A", textAlign: 'justify' }}>
        Please check your inbox for an email from us regarding the next steps. Download our {companyName} App to manage your appointments, talk to your dentist and view your Treatment plan.
      </p>
      <div style={{
        textAlign: 'center'
      }}>
        <a href={iosStoreUrl} target={'_blank'}>
          {" "}
          <img
            src={apple}
            style={{
              height: "100px",
            }}
            alt=""
          />
        </a>
        <a href={playStoreUrl} target={'_blank'}>
          {" "}
          <img
            src={google}
            style={{
              height: "100px",
            }}
            alt=""
          />
        </a>
      </div>
    </Card>
  );
}
