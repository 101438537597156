import React from "react";
import { Link } from "react-router-dom";
import { Result, Button } from "antd";

const NotAuthorized = () => (
  <div>
    {/* <h1>404 - Not Found!</h1>
    <Link to="/">Go Home</Link> */}
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      //   extra={
      //     <Link to="/">
      //       <Button type="primary">Back Home </Button>
      //     </Link>
      //   }
    />
    ,
  </div>
);

export default NotAuthorized;
