import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Row, Col, Button, Space, Spin, Tooltip,Checkbox ,Modal} from "antd";

import { Typography } from "antd";
import axios from "axios";

import RegistrationResultComponent from "../../components/private/registrationResultComponent";
import { patientApiTasks } from "../../models/patient";

import errorModal from "../../components/shared/modals/errorModal";

import image from "../../assets/images/Registration-Webpage-2.png";

import "../../assets/styles/routes/registration.scss";

import {InfoCircleOutlined} from "@ant-design/icons";
import { primaryColor,LogInPageLogo, companyName } from "../../colors-config";
import { autopopulatePatientDetailsApi, registerPatient } from "../../api/patient";
import { confirmPasswordErrorMessage, passwordValidator } from "../../utils/patient";
// import Checkbox from "antd/lib/checkbox/Checkbox";
const PUBLIC_API_BASE_URL = process.env.REACT_APP_API_BASE_URL


const { Title } = Typography;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
      offset: 1,
    },
    sm: {
      span: 8,
      offset: 4,
    },
    lg: {
      span: 8,
      offset: 2,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
      offset: 1,
    },
    sm: {
      span: 16,
      offset: 4,
    },
    lg: {
      span: 16,
      offset: 2,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 4,
    },
    lg: {
      span: 16,
      offset: 2,
    },
  },
};

export default function Registration({ location, match }) {
  const [patientEmail, setPatientEmail] = useState("");
  const [patientNumber, setPatientNumber] = useState("");
  const [patientUniqueId] = useState(match.params.id);
  const [isSpinning, setIsSpinning] = useState(false);
  const [isButtonSpinning, setIsButtonSpinning] = useState(false);
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState("");
  const [termsOfServiceUrl, setTermsOfServiceUrl] = useState("");
  const [isRegistered, setIsRegistered] = useState(false);
  const containerRef = useRef();
  const [isButtonEnable,setIsButtonEnable] = useState(true);
  const [form] = Form.useForm();
  // const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\-]).{8,}$/;
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_+=.])[a-zA-Z\d!@#$%^&*()\-_+=.]{8,}$/;

  useEffect(() => {
    _getPatientData();
    document.body.style = "background:white !important;";
    //document.body.style = "overflow:hidden;";

    return () => {
      document.body.style = "background: #f3f6fa  !important;";

      containerRef.current.style = "background-color:#f7f9f9";
    };
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [patientEmail, patientNumber]);

  useEffect(() => {
    //setIsSpinning(true);
    _getPolicyLinks();
  }, []);
  const _register = async (password) => {
    const model = { randomID: patientUniqueId, password };
    console.log(model);
    try {

      const res = await registerPatient(model);
      console.log(res);
      containerRef.current.style = "background-color:#F3F6FA !important";
      setIsRegistered(true);
      setIsButtonSpinning(false);
    } catch (err) {
      console.log(err);
      setIsButtonSpinning(false);
      // errorModal(err.data.err.message);
      errorModal('SuccessFully!');

    }
  };

  const _getPatientData = async () => {
    setIsSpinning(true);
    try {
      const patientDetailsRes = await autopopulatePatientDetailsApi(patientUniqueId);
      setIsSpinning(false);
      if(patientDetailsRes?.data?.body){
        const patientDetails = patientDetailsRes.data.body;
        setPatientEmail(patientDetails.patientEmail);
        setPatientNumber(patientDetails.patientPhoneNumber);
      }
      else{
        Modal.error({ 
          title: "Error", 
          content:"Patient Details not found" ,
        });
      }
     
    } catch (err) {
      setIsSpinning(false);
      Modal.error({ 
        title: "Error", 
        content:err?.response?.data.msg ? err?.response?.data.msg :"Link is not valid" ,
      });
    }
  };
  const _getPolicyLinks = async () => {
    try {
      const res = await axios.get(`${PUBLIC_API_BASE_URL}/public/getGenericTC`);

      setPrivacyPolicyUrl(res.data.body.privacy_policy.url);
      setTermsOfServiceUrl(res.data.body.terms_of_service.url);
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = (values) => {
    const password = values.password;
    setIsButtonSpinning(true);

    _register(password);
  };

  return (
    <div ref={containerRef} className="registration-container" style={{fontFamily:"Mulish" , backgroundColor:"white !important"}}>
      {isSpinning && (
        <Space size="middle">
          <Spin size="large" />
        </Space>
      )}

      {!isSpinning && (
        <Row>
          <Col lg={12}>
            <div className="registration-image-container">
              <img className="image" src={LogInPageLogo} alt="placeholder"/>
            </div>
          </Col>
          <Col lg={12} md={24} sm={24} xs={24}>
            {isRegistered && (
              <div>
                {/* <img
                alt="smile genius logo"
                src={logo}
                style={{
                  marginTop: "4.5rem",
                  width: "200px",
                  height: "200px",
                  marginBottom: "5%",
                }}
              ></img> */}
                <RegistrationResultComponent></RegistrationResultComponent>
              </div>
            )}
            {!isRegistered && (
              <div  className="registration-form-container">
              <div className="register-form">
                {" "}
                <div className="title-container">
                  <Title
                    level={1}
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                      marginBottom: "0.1rem",
                      fontSize:"25px"
                    }}
                  >
                    Patient Registration
                  </Title>

                  <p
                    style={{
                      fontSize: "12px",
                      color: "#89909A",
                      display: "inline",
                    }}
                  >
                    Please complete your registration. We can't wait to get you started on this journey towards a perfect smile
                  </p>

                  {/* <p
                    style={{
                      display: "inline",
                      marginLeft: "0.2rem",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    {patientEmail}
                  </p> */}
                </div>
                <Form
                  className="registration-form"
                  // style={{ paddingRight: "0.8rem" }}
                  {...formItemLayout}
                  form={form}
                  layout={"vertical"}
                  name="register"
                  onFinish={onFinish}
                  initialValues={{
                    email: patientEmail,
                    phone: patientNumber,
                  }}
                  scrollToFirstError
                >
                  <Form.Item
                    getValueFromEvent={(e) => console.log(e)}
                    name="email"
                    required={true}
                    label="Email address"
                  >
                    <Input disabled={true} />
                  </Form.Item>

                  <Form.Item name="phone" label="Phone number">
                    <Input
                      style={{
                        width: "100%",
                      }}
                      disabled={true}
                    />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    className="password-register-input"
                    label={(
                      <span>
                        Password&nbsp;
                        <Tooltip
                            title="Password must contain at least 1 lowercase alphabetical character, 1 uppercase alphabetical character, 1 numeric character, one special character and have min 8 characters">
                            <InfoCircleOutlined style={{color: primaryColor, marginLeft: "0.5rem"}}
                            />
                        </Tooltip>
                    </span>
                  )}
                    //style={{fontSize:"2px"}}
                    rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                        {
                          pattern:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}()\-_+=!@#%^&*.,><’:;|~`])\S{8,99}$/,
                          message: "Invalid password",
                        },
                        {
                          validator: passwordValidator,
                        },
                      ]}
                    hasFeedback
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    name="confirm"
                    label="Confirm password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(confirmPasswordErrorMessage);
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item  style={{marginBottom: "0"}}>
                    <div style={{textAlign:"left"}}>
                    <p>
                      <Checkbox onChange = {() => {
                        setIsButtonEnable(!isButtonEnable)
                      }}>
                            I agree with {companyName}
                          <a href={termsOfServiceUrl} target="_blank" style={{color:primaryColor}}> Terms of Service</a>{" "}
                          &nbsp;and&nbsp;{" "}
                          <a href={privacyPolicyUrl} target="_blank" style={{color:primaryColor}}>Privacy Policy</a>
                      </Checkbox>
                    </p>
                    </div>
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <div className="btn-container">
                      <Button
                        disabled={isButtonEnable}
                        type="primary"
                        className="registration-button"
                        htmlType="submit"
                        loading={isButtonSpinning}
                      >
                         Create my account
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
              </div>
         
            )}
          </Col>
        </Row>
      )}
    </div>
  );
}