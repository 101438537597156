import React, { useState, useEffect } from "react";
import { message } from "antd";
import qs from "qs";
import ForgotPasswordHandleComponent from "../../components/shared/forgotPasswordHandleComponent";
import ResetResultComponent from "../../components/private/resetResultComponent";

import { Auth } from "aws-amplify";
import { patientApiTasks } from "../../models/patient";

import errorModal from "../../components/shared/modals/errorModal";
import { confirmPasswordEmail } from "../../api/patient";

export default function ResetPassword({ location, match }) {
  const [patientEmail, setPatientEmail] = useState("");
  const [patientCode, setPatientCode] = useState("");


  const [patientUniqueId] = useState(match.params.id);
  const [isSpinning, setIsSpinning] = useState(false);

  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    var obj = qs.parse(location.search ,{ ignoreQueryPrefix: true });
    if(obj && obj.email && obj.code){
      setPatientEmail(obj.email);
      setPatientCode(obj.code);
    }
    else{
      message.error("Code is expired");
    }
   
    // _getUserEmail();
  }, []);

  const _getUserEmail = async () => {
    try {
      const res = await patientApiTasks.getAll(
        "patient/forgotPassword/getEmail",
        {
          randomId: patientUniqueId,
        }
      );
      const email = res.body.patient_email;
      setPatientEmail(email);
      confirmResetPassword(email);
    } catch (error) {
      console.error(error);
    }
  };

  const confirmResetPassword = async (email) => {
    try {
      const res = await Auth.forgotPassword(email);
      console.log(res, "res");
      message.success("Verification Code successfully sent to your email");
    } catch (error) {
      console.log(error);
      message.error(error.message);
    }
  };

  const resetPassword = ( newPassword) => {
    Auth.forgotPasswordSubmit(patientEmail, patientCode, newPassword)
      .then(async(data) => {
        console.log(data);
        // history.push("/login");
        await confirmPasswordEmailApi(patientEmail);
        setIsReset(true);
       
        // message.success("Password successfully changed");
      })
      .catch((err) => {
        message.error(err.message);
        setIsReset(false);
      });
  };

  const confirmPasswordEmailApi=async (email)=>{
    try {
      await confirmPasswordEmail({ email });
    } catch (error) {
      console.log("error",error);
    }
  }

  return (
    <div>
      {/* <div
        style={{
          backgroundColor: "white",
          height: "4.7rem",
          position: "relative",

          boxShadow: "3px 6px 8px rgba(0, 0, 0, 0.03)",
        }}
      >
        <Row justify="start">
          <Col
            xxl={{ span: 6, offset: 1 }}
            xl={{ span: 6, offset: 1 }}
            lg={{ span: 6, offset: 1 }}
            md={24}
          >
            <Row justify="start">
              <img
                style={{
                  width: "110px",
                  height: "110px",

                  marginTop: "-15px",
                  marginRight: "2.1rem",
                }}
                src={logo}
                alt="logo"
              />
            </Row>
          </Col>
        </Row>
      </div> */}
      <div
        style={{
          width: "45rem",
          margin: "4rem auto ",
          //   backgroundColor: "red",
        }}
      >
        {!isReset ? (
          <ForgotPasswordHandleComponent
          patientEmail={patientEmail}
            forgotPassword={resetPassword}
          ></ForgotPasswordHandleComponent>
        ) : (
          <ResetResultComponent />
        )}
      </div>
    </div>
  );
}
