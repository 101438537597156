import React from "react";
import { Row, Col } from "antd";
// import logo from "../assets/images/Smile-Genius-Logo-copy-2.png";

import "../assets/styles/components/headerComponent.scss";
import { companyType, headerColor, headerLogo } from "../colors-config";

export default function HeaderComponent() {
  return (
    <div
      style={{
        backgroundColor: headerColor,
        height: "4.7rem",
        position: "relative",

        // boxShadow: "3px 6px 8px rgba(0, 0, 0, 0.03)",
      }}
    >
      <Row justify="start">
        <Col
          xxl={{ span: 6, offset: 1 }}
          xl={{ span: 6, offset: 1 }}
          lg={{ span: 6, offset: 1 }}
          md={24}
        >
          <Row justify="start">
            <img className={`header-logo ${companyType===4?" clearx-logo":""}`} src={headerLogo} alt="logo" />
          </Row>
        </Col>
      </Row>
    </div>
  );
}
