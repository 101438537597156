import React, { useEffect, useState } from "react";

import { Col, Row, Calendar, Select, Spin, Typography } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";
import dayjs from "dayjs";

import "../assets/styles/components/calendarComponent.scss";

// moment.updateLocale("en", {
//   weekdaysMin: ["S", "M", "T", "W", "T", "F", "S"],
// });

const DATE_FORMAT = "L";

const { Title } = Typography;

const convertDateStrToMoment = (holidayDates) => {
  if (holidayDates && holidayDates.length) {
    let arr = holidayDates.map((holidays) => {
      return dayjs(holidays).format(DATE_FORMAT);
    });
    console.log(arr, "arr");

    return arr;
  } else {
    return [];
  }
};

export default function CalendarComponent({
  onDateChange,
  isSpinning,
  clinicWorkingSchedule,
}) {
  // console.log(nonWorkingDays, "offdays");
  const months = [];
  const startOfCurrentDay = dayjs().startOf("day");

  const [holidays, setHolidays] = useState([]);
  const [offDays, setOffDays] = useState([]);

  useEffect(() => {
    // const date = new Date();
    const momentDate = dayjs();
    const dateStr = getDateStr(momentDate);
    onDateChange(dateStr, momentDate);
  }, []);


  const getDateStr = (date) => {
    // const dateString = `${date.getDate()} ${
    //   months[date.getMonth()]
    // } ${date.getFullYear()}`;

    return date.format("YYYY-MM-DD");
  };

  const dateChanged = (value) => {
    const date = value;

    const dateStr = getDateStr(date);

    onDateChange(dateStr, value);
  };

  const disableDates = (currentDate) => {
    const today = dayjs().startOf("day");
    const current = dayjs(currentDate).startOf("day");
    const currentDay = current.day();
    const currentValue = current.valueOf();

    if (!clinicWorkingSchedule) {
      return currentValue < today.valueOf();
    }
    const findWorkDayKey = clinicWorkingSchedule.newWorkingDays[currentDay];
    return (
      currentValue < today.valueOf() ||
      currentDay!=findWorkDayKey ||
      (Object.keys(clinicWorkingSchedule.holidays).length &&
        clinicWorkingSchedule.holidays[currentValue])
    );
  };

  const calendarHeader = ({ value, type, onChange, onTypeChange }) => {
  
    const calendarArrowClick = (type) => {
      let newValue = value.clone();
      if(type=="add"){
        newValue = newValue.add(1, 'month')
      }
      else{
        newValue = newValue.subtract(1, 'month')
      }
      onChange(newValue);
     
    };
    return (
      <div style={{ padding: 8 }}>
        <Row
          gutter={8}
          style={{
            margin: "0.2rem 0.5rem 0.15rem 0rem",
            display: "flex",
            alignItems: "center",
          }}
          justify="space-between"
        >
          <Col>
            <LeftOutlined
              onClick={() => {
                calendarArrowClick("subtract");
              }}
            />
          </Col>
          <Col>
            <p style={{ fontFamily: "'Poppins', sans-serif" }}>
            <span style={{textTransform:"uppercase"}}>{value.format("MMM")}</span> {value.year()}
            </p>
          </Col>

          <Col>
            <RightOutlined
              onClick={() => {
                calendarArrowClick("add");
              }}
            />
          </Col>
        </Row>
      </div>
    );
    // }
  };

  return ( 
    <div className="calendar-container">
      <Row justify="start">
        <Title className="calendar-header" level={4}>
          Select Time
        </Title>
      </Row>
      <Spin spinning={isSpinning}>
        <div className="site-calendar-customize-header-wrapper">
          <Calendar
            fullscreen={false}
            // monthFullCellRender={() => <div>dsfsdf</div>}
            disabledDate={disableDates}
            headerRender={calendarHeader}
            onSelect={dateChanged}
          />
        </div>
      </Spin>
    </div>
  );
}
