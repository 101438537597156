import { apiProvider } from "./provider";
const tenantId = process.env.REACT_APP_SMILE_TENENT_ID;
export default class ApiCore {
  constructor(options, axiosInstance) {
    if (options.getAll) {
      this.getAll = (url, query) => {
        const config = {
          params: query,
        };
        return apiProvider.getAll(url, config, axiosInstance);
      };
    }

    if (options.getSingle) {
      this.getSingle = (url, query) => {
        const config = {
          params: query,
        };
        return apiProvider.getSingle(url, config, axiosInstance);
      };
    }

    if (options.getClinic) {
      this.getClinic = (url, query) => {
        const config = {
          params: query,
        };
        return apiProvider.getSingle(url, config, axiosInstance);
      };
    }

    if (options.post) {
      this.post = (url, model) => {
        return apiProvider.post(url, model, axiosInstance);
      };
    }

    if (options.put) {
      this.put = (url, model) => {
        return apiProvider.put(url, model, axiosInstance);
      };
    }

    if (options.patch) {
      this.patch = (url, model) => {
        return apiProvider.patch(url, model, axiosInstance);
      };
    }

    if (options.remove) {
      this.remove = (url, id) => {
        return apiProvider.remove(url, id, axiosInstance);
      };
    }
    axiosInstance.interceptors.request.use(
      (config) => {
        if(tenantId){
          config.headers["tenantId"] =tenantId;
        }

        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  }
}
