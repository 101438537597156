import { combineReducers } from "redux";

import errorReducer from "./errorReducer";

import patientReducer from "./patientReducer";

export default combineReducers({
  error: errorReducer,

  patient: patientReducer,
});
