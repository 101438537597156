import React from "react";

import { Card, Typography } from "antd";
import image from "../../assets/images/email-sent.png";

const { Title } = Typography;

export default function AppointmentResultComponent() {
  return (
    <Card
      style={{ margin: "5rem auto 0 auto", borderRadius: "12px" }}
      className='appoinment-card'
    >
      <Title level={2} style={{ fontFamily: "'Poppins', sans-serif" }}>
        Your appointment request has been sent successfully!
      </Title>
      <p style={{ fontWeight: "525", fontSize: "20px", color: "#595959" }}>
        Awaiting confirmation by the Dentist
      </p>
      <img
        style={{
          // backgroundColor: "red",
          marginBottom: "-50px",
          marginTop: "-30px",

          marginRight: "2rem",
        }}
        src={image}
        alt="email logo"
      />

      <p style={{ color: "#77849E", fontSize: "18px" }}>
        You will be sent an email confirmation link soon
      </p>
    </Card>
  );
}
