import api from "./api";

const registerPatient = (data) => {
    return api({
        method: "POST",
        url: `/patient/registerPatient`,
        data: data
    })
};

const autopopulatePatientDetailsApi = (randomID) => {
    return api({
        method: "GET",
        url: `/patient/autopopulatePatientDetails?randomID=${randomID}`
    })
};

const confirmPasswordEmail = (data) => {
    return api({
        method: "POST",
        data,
        url: `/patient/auth/confirmpassword-email`
    })
};

 const getProfileAndAvailability = (params) => {
    return api({
      method: "GET",
      url: `/clinic/getProfileAndAvailability`,
      params
    }).then((res) => res.data);
  };

export {
    registerPatient,
    autopopulatePatientDetailsApi,
    confirmPasswordEmail,
    getProfileAndAvailability
}