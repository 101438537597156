import ApiCore from "../services/api/utilities/core";
import axios from "axios";

export const patientAxiosInstance = axios.create();


export const patientApiTasks = new ApiCore(
  {
    getAll: true,
    getSingle: true,
    getClinic: true,
    post: true,
    put: false,
    patch: true,
    delete: false,

    //   plural: plural,
    //   single: single,
  },
  patientAxiosInstance
);

patientApiTasks.massUpdate = () => {};
