import React from "react";

import { Card, Typography } from "antd";
import image from "../../assets/images/check-icon.png";

const { Title } = Typography;

export default function ResetResultComponent() {
  return (
    <Card
      style={{
        width: "80%",
        margin: "4rem auto",

        borderRadius: "12px",
      }}
    >
      <Title
        level={2}
        style={{
          fontFamily: "'Poppins', sans-serif",
          width: "30rem",

          margin: "10%  auto 0 auto",
        }}
      >
        Password successfully reset
      </Title>
      <p style={{ fontSize: "1.1rem" }}>
        {" "}
        You can now login to the app on your mobile device with the new password
      </p>

      <img
        style={{
          marginTop: "7%",
          marginBottom: "10%",

          // backgroundColor: "red",
          //   marginRight: "2rem",
        }}
        src={image}
        alt="email logo"
      />
    </Card>
  );
}
